import { linkUrl } from '../../../utils/url';
import type { MenuItemProps } from '../../partials/widget/widget-menu/widget-menu-default';
import { Icon } from '../Icon';
import Link from '../Link/Link';
import styles from './MenuLink.module.scss';

type MenuLinkProps = {
  menuItem: MenuItemProps;
  dataVarsAction?: string;
  disableClick?: boolean;
};

const MenuLink = ({ menuItem, dataVarsAction, disableClick }: MenuLinkProps) => (
  <>
    {menuItem.type === 'content' && <></>}
    {menuItem.type === 'space' && <Link href="#">{menuItem.title}</Link>}
    {disableClick && menuItem.target !== '_blank' && <div>{menuItem.title ?? ''}</div>}
    {(menuItem.type === 'link' || menuItem.type === 'section') && (!disableClick || menuItem.target === '_blank') ? (
      <Link
        noFollow={menuItem?.noFollow}
        href={!menuItem.url ? '#' : linkUrl(menuItem.url, menuItem.type === 'section')}
        target={menuItem.target ?? undefined}
        data-vars-category="navigation"
        data-vars-label={menuItem.title ?? ''}
        data-vars-action={dataVarsAction ?? ''}
      >
        {menuItem.title ?? ''}
        {menuItem.target && menuItem.target === '_blank' && (
          <Icon name="arrowRightUp" className={styles.externalLinkIcon} />
        )}
      </Link>
    ) : (
      <></>
    )}
  </>
);

export default MenuLink;
